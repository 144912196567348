import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { MdMenu } from "react-icons/md";
import LogoutIcon from 'images/logout.svg';
import { toggleSideBar } from "components/sidebar/sideBarSlice";
import Popup from "components/ConfirmPopup";
import { showLogoutPopup } from "components/ConfirmPopup/popupSlice";
import TranslateText from "translation/TranslateText";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: "0",
    borderBottom: "1px solid #dee2e6",
    left: "var(--width-sideBar)",
    fontSize: "1.8rem",
    width: "calc(100vw - var(--width-sideBar))",
    padding: "1rem 2rem",
    fontWeight: "600",
    zIndex: "99",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    display: "flex",
    justifyContent: "flex-end",
    transition: "width 0.3s ease-in-out",
    transformOrigin: "left",

    "@media only screen and (max-width: 56.25em)": {
      justifyContent: "space-between",
    },

    "& > button": {
      backgroundColor: "transparent",
      border: "none",
    },
  },
  btn: {
    fontSize: "1.1rem",
    fontWeight: "500",
    cursor: "pointer",
  },
  active: {
    width: "100vw",
    left: 0,
  },

  menuBtn: {
    display: "none",

    "&:focus": {
      outline: "none",
    },

    "& > svg": {
      fontSize: "1.6rem",
    },

    "@media only screen and (max-width: 56.25em)": {
      display: "block",
    },
  },
});

const TopBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const sideBarStatus = useSelector((state) => state.sideBar);

  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(toggleSideBar());
  };

  const handleLogout = () => {
    dispatch(showLogoutPopup({ logout: true }));
  };

  const popupDisplay = useSelector((state) => state.popup.logout);

  return (
    <>
      <nav
        className={`${classes.root} ${!sideBarStatus ? classes.active : ""}`}
      >
        <button onClick={handleClick} className={classes.menuBtn}>
          <MdMenu />
        </button>
        <button onClick={handleLogout} className={classes.btn}>
          <img src={LogoutIcon} alt="logoutIcon" style={{ width: "20px", marginRight: "5px" }} />
          <TranslateText id="logout" />
        </button>
      </nav>
      {popupDisplay ? <Popup parent="topbar" /> : false}
    </>
  );
};

export default TopBar;
