import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import * as routes from 'routes';
import CustomRoute from 'routes/customRoute';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { messages } from 'translation';

// Pages

import Login from 'features/Login';
import AllUsers from 'features/allUsers/AllUsers';
import AllReportedUsers from 'features/allReportedUsers/AllReportedUsers';
import Categories from 'features/allCategories';
import Skills from 'features/allSkills';
import AllJobs from 'features/allJobs';
import MatchingPercentage from 'features/matchingPercentage';

const App = () => {
  const authenticated = localStorage.getItem('token');
  const currentLang = useSelector(state => state.auth.language);

  return (
    <IntlProvider messages={messages[currentLang]} locale={currentLang}>
      <Router>
        <Switch>
          <Route
            render={props =>
              authenticated ? (
                <Redirect to={routes.ALL_USERS} />
              ) : (
                <Login {...props} />
              )
            }
            path={routes.LOGIN}
            exact
          />
          <CustomRoute
            restricted
            path={routes.ALL_USERS}
            exact
            component={AllUsers}
          />
          <CustomRoute
            restricted
            path={routes.ALL_REPORTED_USERS}
            exact
            component={AllReportedUsers}
          />
          <CustomRoute
            restricted
            path={routes.CATEGORY}
            exact
            component={Categories}
          />
          <CustomRoute
            restricted
            path={routes.SKILLS}
            exact
            component={Skills}
          />
          <CustomRoute
            restricted
            path={routes.JOBS}
            exact
            component={AllJobs}
          />
          <CustomRoute
            restricted
            path={routes.MATCHING_PERCENTAGE}
            exact
            component={MatchingPercentage}
          />
        </Switch>
      </Router>
    </IntlProvider>
  );
};

export default App;
