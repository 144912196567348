import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { IoIosClose } from 'react-icons/io';
import { makeStyles } from '@material-ui/core/styles';

import { hidePopup } from 'components/ConfirmPopup/popupSlice';
import { logOut, changeLanguage } from 'features/Login/loginSlice';
import { deleteUserRequest } from 'features/allUsers/allUserListSlice';
import TranslateText from 'translation/TranslateText';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { deleteReportUserRequest } from 'features/allReportedUsers/allReportedUserListSlice';

const useStyles = makeStyles(_theme => ({
  modalContainer: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, .8)',
  },

  modal: {
    position: 'absolute',
    left: '50%',
    top: '35%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
    minWidth: '320px',
  },

  close: {
    display: 'block',
    marginLeft: 'auto',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#d3d3d3',

    '& > svg': {
      fontSize: '3rem',
    },
  },

  form: {
    maxHeight: '88vh',
    overflow: 'auto',
    backgroundColor: '#fff',
    padding: '1rem 1rem 0 1rem',
  },

  btnContainer: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'center',
    padding: '1rem',
  },
  text: {
    textAlign: 'center',
  },
  reasonContainer: {
    textAlign: 'center',
    marginTop: '1rem',
  },
  languageBtnContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Modal = ({ parent }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const intl = useIntl();

  const id = useSelector(state => state.popup.id);
  const currentLang = useSelector(state => state.auth.language);

  function onDismiss() {
    dispatch(hidePopup());
  }

  function handleYes() {
    switch (parent) {
      case 'topbar':
        dispatch(logOut());
        break;
      case 'user':
        dispatch(deleteUserRequest({ userID: id }));
        break;
      case 'reportUser':
        dispatch(deleteReportUserRequest({ userID: id }));
        break;
      default:
        break;
    }
    onDismiss();
  }

  const handleLanguageChange = e => {
    dispatch(changeLanguage(e.target.value));
    onDismiss();
  };

  const heading = () => {
    return parent === 'topbar' ? (
      <TranslateText id="logout_warning" />
    ) : (
      <TranslateText id="delete_warning" />
    );
  };

  return ReactDOM.createPortal(
    <div onClick={onDismiss} className={styles.modalContainer}>
      <div onClick={e => e.stopPropagation()} className={styles.modal}>
        <button onClick={onDismiss} className={styles.close}>
          <IoIosClose />
        </button>
        {parent === 'sidebar' ? (
          <div className={styles.form}>
            <p className={styles.text}>
              <TranslateText id="change_language" />
            </p>
            <div className={styles.languageBtnContainer}>
              <RadioGroup
                row
                value={currentLang}
                aria-label="language"
                name="radio-buttons-group"
                onChange={handleLanguageChange}
              >
                <FormControlLabel
                  value="en"
                  control={<Radio />}
                  label={intl.formatMessage({ id: 'english' })}
                />
                <FormControlLabel
                  value="de"
                  control={<Radio />}
                  label={intl.formatMessage({ id: 'german' })}
                />
              </RadioGroup>
            </div>
          </div>
        ) : (
          <div className={styles.form}>
            <p className={styles.text}>{heading()}</p>
            <div className={styles.btnContainer}>
              <button onClick={onDismiss} className={styles.btn}>
                <TranslateText id="no" />
              </button>
              |
              <button onClick={handleYes} className={styles.btn}>
                <TranslateText id="yes" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>,
    document.querySelector('#popup')
  );
};

export default Modal;
