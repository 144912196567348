import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from 'components/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import useAllReportedUserList from 'customHooks/useAllReportedUserList';
import { emptyAllReportedUserList } from './allReportedUserListSlice';
import { AiFillDelete } from 'react-icons/ai';
import { showPopup } from 'components/ConfirmPopup/popupSlice';
import Popup from 'components/ConfirmPopup';
import TranslateText from 'translation/TranslateText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from 'Modal';
import { showModal } from 'Modal/modalSlice';

const button = {
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  fontSize: '24px',
  paddingLeft: '10px',
};

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
  },
  container: {
    margin: '1rem',
  },
  pagination: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '60px',
  },
  deleteButton: {
    ...button,
    color: 'red',
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '1rem',
  },
});

const AllReportedUsers = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const limit = 10;
  const [callStart, setCallStart] = useState(0);
  const [filter, setFilter] = useState(1);
  const [page, setPage] = useState(1);
  const [reportedData, setReportedData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const popupDisplay = useSelector(state => state.popup.visibility);
  const modalStatus = useSelector(state => state.modal.visibility);

  useEffect(() => {
    setCallStart((page - 1) * limit);
  }, [page]);

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setCallStart(0);
    dispatch(emptyAllReportedUserList());
  }, [dispatch]);

  const allReportedUsersList = useAllReportedUserList(
    callStart,
    limit,
    filter,
    refresh
  );

  const totalReportedUsersCount = useSelector(
    state => state.reportedUserList.totalUsers
  );
  const refreshPage = useSelector(state => state.reportedUserList.refreshPage);

  useEffect(() => {
    setRefresh(!refresh);
  }, [refreshPage]);

  const totalPages_pre = totalReportedUsersCount / limit;
  const totalPages =
    totalReportedUsersCount % limit === 0
      ? totalPages_pre
      : Math.trunc(totalPages_pre) + 1;

  const handleDelete = id => {
    dispatch(showPopup({ visibility: true, id }));
  };

  const handleChange = e => {
    setFilter(e.target.value);
    handlePageChange(e, 1);
  };

  const showReportedList = (id, data) => {
    console.log('data :', data);
    dispatch(showModal({ id }));
    setReportedData(data);
  };

  const renderRow = () => {
    return allReportedUsersList.map((item, index) => {
      return (
        <TableRow key={item._id}>
          <TableCell align="center">{index + 1}</TableCell>
          <TableCell align="center">
            {filter === 1 ? item?.user?.fullName : item?.company?.companyName}
          </TableCell>
          <TableCell
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={() => showReportedList(item._id, item.reportedBy)}
          >
            {item?.totalReports}
          </TableCell>
          <TableCell align="center">
            <button
              onClick={() => handleDelete(item._id)}
              className={classes.deleteButton}
            >
              <AiFillDelete />
            </button>
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    return (
      <>
        <div className={classes.filter}>
          <Select
            name="subType"
            labelId="formSelectLabel"
            id="formSelect"
            label="Type"
            value={filter}
            onChange={handleChange}
          >
            <MenuItem value={1}>
              <TranslateText id="candidate" />
            </MenuItem>
            <MenuItem value={2}>
              <TranslateText id="company" />
            </MenuItem>
          </Select>
        </div>
        <TableContainer component={Paper} className={classes.container}>
          <Table aria-label="simple table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <TranslateText id="s_no" />
                </TableCell>
                <TableCell align="center">
                  <TranslateText id="user_name" /> /{' '}
                  <TranslateText id="company_name" />
                </TableCell>
                <TableCell align="center">
                  <TranslateText id="reports_count" />
                </TableCell>
                <TableCell align="center">
                  <TranslateText id="action" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{allReportedUsersList && renderRow()}</TableBody>
          </Table>
        </TableContainer>
        <div className={classes.pagination}>
          <Pagination
            color="primary"
            count={totalPages ? totalPages : 0}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      </>
    );
  };

  return (
    <Skeleton>
      {renderTable()}
      {popupDisplay ? <Popup parent="reportUser" /> : false}
      {modalStatus ? (
        <Modal
          title="Reported List"
          type="viewReportedList"
          modalStyles={{ width: '50%' }}
          data={reportedData}
        />
      ) : (
        ''
      )}
    </Skeleton>
  );
};

export default AllReportedUsers;
