import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allReportedUserListRequest } from 'features/allReportedUsers/allReportedUserListSlice';

const useAllReportedUserList = (start, limit, filter, refresh) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allReportedUserListRequest([start, limit, filter]));
  }, [start, limit, filter, dispatch, refresh]);

  return useSelector(state => {
    if (state.reportedUserList.list)
      return Object.values(state.reportedUserList.list);
    return [];
  });
};

export default useAllReportedUserList;
