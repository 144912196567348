import React from 'react';
import { useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import { IoIosClose } from 'react-icons/io';
import { makeStyles } from '@material-ui/core/styles';

import { hideModal, hideSubModal } from 'Modal/modalSlice';
import ViewReportedList from 'features/allReportedUsers/viewReportedList';

const useStyles = makeStyles(_theme => ({
  modalContainer: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: 'rgba(0, 0, 0, .8)',
  },

  modal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-40%, -60%)',
    zIndex: 99,
    width: '70%',
    minWidth: '320px',
    marginTop: '50px',
  },

  close: {
    display: 'block',
    marginLeft: 'auto',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#fff',

    '& > svg': {
      fontSize: '3rem',
    },
  },

  form: {
    maxHeight: '88vh',
    overflow: 'auto',
  },
}));

const Modal = ({ title, type, modalStyles, data, subModal }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  function onDismiss() {
    dispatch(hideModal());
  }

  function onSubDismiss() {
    dispatch(hideSubModal());
  }

  const renderForm = () => {
    switch (type) {
      case 'viewReportedList':
        return <ViewReportedList title={title} data={data} modal />;
      default:
        return false;
    }
  };

  return ReactDOM.createPortal(
    <div onClick={onDismiss} className={styles.modalContainer}>
      <div
        onClick={e => e.stopPropagation()}
        className={styles.modal}
        style={modalStyles}
      >
        <button
          onClick={subModal ? onSubDismiss : onDismiss}
          className={styles.close}
        >
          <IoIosClose />
        </button>

        <div className={styles.form}>{renderForm()}</div>
      </div>
    </div>,
    document.querySelector('#modal')
  );
};

export default Modal;
