import { configureStore } from '@reduxjs/toolkit';

import loginReducer from 'features/Login/loginSlice';
import allUserReducer from 'features/allUsers/allUserListSlice';
import sideBarReducer from 'components/sidebar/sideBarSlice';
import popupReducer from 'components/ConfirmPopup/popupSlice';
import allReportedUserReducer from 'features/allReportedUsers/allReportedUserListSlice';
import allCategoriesReducer from 'features/allCategories/categoriesListSlice';
import skillsReducer from 'features/allSkills/skillListSlice';
import jobsReducer from 'features/allJobs/jobsListSlice';
import matchingPercentageReducer from 'features/matchingPercentage/matchingPercentageListSlice';
import modalReducer from 'Modal/modalSlice';

export default configureStore({
  reducer: {
    auth: loginReducer,
    allUserList: allUserReducer,
    reportedUserList: allReportedUserReducer,
    allCategories: allCategoriesReducer,
    sideBar: sideBarReducer,
    popup: popupReducer,
    skills: skillsReducer,
    jobs: jobsReducer,
    matchingPercentage: matchingPercentageReducer,
    modal: modalReducer,
  },
});
