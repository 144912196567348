import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import TranslateText from 'translation/TranslateText';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2, 0),
    },
  },
  modal: {
    overflow: 'auto',
    maxHeight: '80vh',
  },
  pagination: {
    width: '120px',
    margin: '30px auto 60px',
  },
  rootDiv: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ViewReportedList = ({ modal, data }) => {
  console.log('data :', data);
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(10);
  const [filteredData, setFilteredData] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setStartIndex((value - 1) * 10);
    setLastIndex(value * 10);
  };

  useEffect(() => {
    const newData = data.slice(startIndex, lastIndex);
    setFilteredData(newData);
  }, [startIndex, lastIndex, data]);

  const totalReportCount = data.length;

  const totalPages_pre = totalReportCount / 10;
  const totalPages =
    totalReportCount % 10 === 0
      ? totalPages_pre
      : Math.trunc(totalPages_pre) + 1;

  const renderRow = () => {
    console.log('filteredData :', filteredData);
    return filteredData.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell align="center" component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell align="center" component="th" scope="row">
            {item.reportedBy}
          </TableCell>
          <TableCell align="center" component="th" scope="row">
            {item.reportedReason}
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Card
      variant="outlined"
      className={`${classes.root} ${modal ? classes.modal : ''}`}
      color="primary"
    >
      <CardHeader title={<TranslateText id="reported_list" />} />
      <div className={classes.rootDiv}>
        <TableContainer className={classes.container} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">
                  <TranslateText id="reported_by" />
                </TableCell>
                <TableCell align="center">
                  <TranslateText id="reason" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{data && renderRow()}</TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.pagination}>
        <Pagination
          color="primary"
          rowsperpage={1}
          count={totalPages ? totalPages : 0}
          page={page}
          onChange={handlePageChange}
        />
      </div>
    </Card>
  );
};

export default ViewReportedList;
