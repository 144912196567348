import React, { useState, useEffect } from 'react';
import Skeleton from 'components/Skeleton';
import { Typography, Slider, Input } from '@material-ui/core';
import {
  getMatchingPercentageRequest,
  updateMatchingPercentageRequest,
} from './matchingPercentageListSlice';
import { useDispatch, useSelector } from 'react-redux';
import TranslateText from 'translation/TranslateText';

const MatchingPercentage = () => {
  const [value, setValue] = useState(-1);
  const [valueToSet, setValueToSet] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMatchingPercentageRequest());
  }, [dispatch]);

  const { id, percentage } = useSelector(state => state.matchingPercentage);

  useEffect(() => {
    setValue(percentage);
    setValueToSet(percentage);
  }, [id]);

  const marks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];

  useEffect(() => {
    if (value !== percentage && value >= 0) {
      dispatch(
        updateMatchingPercentageRequest({ id: id, percentageNumber: value })
      );
    }
  }, [value]);

  const handleSliderChange = (_event, newValue) => {
    if (value !== newValue && id) {
      setValue(newValue);
    }
  };

  const handleInputChange = event => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <Skeleton>
      <div style={{ width: '480px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography id="non-linear-slider" gutterBottom>
            <TranslateText id="matching_skill_percentage" />
          </Typography>
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </div>
        <div>
          <Slider
            value={valueToSet}
            marks={marks}
            onChange={(_event, newValue) => setValueToSet(newValue)}
            onChangeCommitted={handleSliderChange}
            valueLabelDisplay="auto"
          />
        </div>
      </div>
    </Skeleton>
  );
};

export default MatchingPercentage;
