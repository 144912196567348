import React from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import SideBar from "components/sidebar";
import TopBar from "components/TopBar";
import { hideSideBar } from "components/sidebar/sideBarSlice";

const useStyles = makeStyles({
  container: {
    padding: "1rem 2rem 1rem 20rem",
    margin: "4rem 0 0 0",
    width: "98%",
    marginLeft: "10px",

    "@media only screen and (max-width: 56.25em)": {
      padding: "1rem 2rem 1rem 1rem",
    },
  },
});

const Skeleton = ({ children, page }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(hideSideBar());
  };

  return (
    <div>
      <SideBar />
      <TopBar />

      <div onClick={handleClick} style={{ minHeight: "80vh", marginTop: page === "root" ? "8rem" : "4rem" }}>
        <Grid className={classes.container} container spacing={4}>
          {children}
        </Grid>
      </div>
    </div>
  );
};

export default Skeleton;
