import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allUserListRequest } from 'features/allUsers/allUserListSlice';

const useAllUserList = (keyword, filter, start, limit, refresh) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allUserListRequest([keyword, filter, start, limit]));
  }, [start, limit, keyword, dispatch, filter, refresh]);

  return useSelector(state => {
    if (state.allUserList.list) return Object.values(state.allUserList.list);
    return [];
  });
};

export default useAllUserList;
