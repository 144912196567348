import React, { useState, useEffect } from 'react';
import Skeleton from 'components/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useAllJobsList from 'customHooks/useAllJobsList';
import { Button, InputBase, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { BsPlusLg } from 'react-icons/bs';
import TextField from '@material-ui/core/TextField';
import TranslateText from 'translation/TranslateText';
import useAllCategoriesList from 'customHooks/useAllCategoriesList';
import useAllSkillsList from 'customHooks/useAllSkillsList';
import {
  addNewJobRequest,
  deleteJobRequest,
  emptyAllJobsList,
  getSingleJobRequest,
  updateJobRequest,
} from './jobsListSlice';
import usePagination from 'customHooks/usePagination';
import { Formik } from 'formik';
import { string, object } from 'yup';
import Multiselect from 'multiselect-react-dropdown';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    gap: '40px',
  },
  listWidth: {
    width: '300px',
  },
  searchContainer: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    border: '2px solid #4B545C',
  },
  listContainer: {
    height: '500px',
    margin: 0,
    overflow: 'hidden auto',
  },
  listItem: {
    background: '#34495e',
    color: 'white',
    cursor: 'pointer',
    marginBottom: '1px',
    padding: '10px',
    transition: '0.75s all',
  },
  formContainer: {
    width: '50%',
  },
  formInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  fullWidth: {
    width: '100%',
  },
  cursor: {
    cursor: 'pointer',
  },
  optionValueContainer: {
    display: 'flex',
    borderRadius: '4px',
    border: '.1em solid #C4C4C4',
  },
  optionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden auto',
    maxHeight: '200px',
  },
  optionValueInnerContainer: {
    padding: '0 0.5em',
    width: '100%',
  },
  optionInnerContainer: {
    maxWidth: '100%',
    margin: '.4em .4em 0 0',
  },
  optionValue: {
    maxWidth: '100%',
    minHeight: '2em',
    padding: '0 .5em',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '1rem',
    borderRadius: '10px',
    backgroundColor: '#E5E5E5',
    gap: '12px',
  },
  dropdownContainer: {
    overflow: 'hidden auto',
    border: '1px solid #C4C4C4',
    maxHeight: '200px',
    borderRadius: '4px',
    borderTop: 'none',
  },
  dropdownOption: {
    color: '#212529',
    cursor: 'pointer',
    marginBottom: '1px',
    padding: '10px',
    transition: '0.75s all',
  },
  active: {
    backgroundColor: '#E5E5E5',
  },
  buttonContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  hidden: {
    display: 'none',
  },
});

const AllJobs = () => {
  const limit = 20;
  const scrollJobElementID = 'scrollJobElement';

  const classes = useStyles();
  const [keyword, setKeyword] = useState('');
  const [callStart, setCallStart] = useState(0);
  const intl = useIntl();
  const dispatch = useDispatch();

  const allJobsList = useAllJobsList(keyword, callStart, limit);
  const allCategories = useAllCategoriesList('', 0, 10000000, true);
  const allSkills = useAllSkillsList('', 0, 10000000, true);

  const totalJobsCount = useSelector(state => state.jobs.totalCount);

  const [showJobForm, setShowJobForm] = useState(false);
  const [jobTitle, setJobTitle] = useState({
    en: '',
    de: '',
  });
  const [selectedJobId, setSelectedJobId] = useState('');
  const singleJob = useSelector(state => state.jobs.singleJobData);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  usePagination(totalJobsCount, callStart, setCallStart, scrollJobElementID);

  const [typing, setTyping] = useState('');
  useEffect(() => {
    const timerId = setTimeout(() => {
      setCallStart(0);
      setKeyword(typing);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [typing]);

  useEffect(() => {
    setCallStart(0);
    dispatch(emptyAllJobsList());
  }, [dispatch]);

  useEffect(() => {
    setJobTitle(singleJob?.jobTitle);
    let catData = singleJob?.categories;
    if (catData && catData.length) {
      catData = singleJob?.categories.map(data => {
        if (data._id) {
          return {
            _id: data._id,
            categoryName: data.categoryName?.en + ' / ' + data.categoryName?.de,
          };
        }
      });
      if (catData && catData.length && !catData[0]) {
        catData = [];
      }
    }
    setSelectedCategories(catData && catData.length ? catData : []);
    let skillData = singleJob?.skills;
    if (skillData && skillData.length) {
      skillData = singleJob?.skills.map(data => {
        if (data._id) {
          return {
            _id: data._id,
            skillName: data.skillName?.en + ' / ' + data.skillName?.de,
          };
        }
      });
      if (skillData && skillData.length && !skillData[0]) {
        skillData = [];
      }
    }
    setSelectedSkills(skillData && skillData.length ? skillData : []);
  }, [singleJob]);

  const clearData = () => {
    setKeyword('');
    setJobTitle({
      en: '',
      de: '',
    });
    setSelectedJobId('');
    setShowJobForm(false);
    setSelectedCategories([]);
    setSelectedSkills([]);

    if (renderSkill) {
      setRenderSkill(false);
    }
  };

  const showForm = () => {
    setShowJobForm(true);
    setJobTitle({
      en: '',
      de: '',
    });
    setSelectedJobId('');
    setSelectedCategories([]);
    setSelectedSkills([]);
  };

  const handleSingleJob = id => {
    setSelectedJobId(id);
    setShowJobForm(true);
    if (renderSkill) {
      setRenderSkill(false);
    }
    dispatch(getSingleJobRequest({ id: id }));
  };

  const languageToUse = localStorage.getItem('language') || 'en';

  const renderJobsList = () => {
    return (
      <ul
        id={scrollJobElementID}
        className={`${classes.listContainer} scrollElement`}
      >
        {allJobsList.map(data => {
          return (
            <li
              key={data._id}
              className={classes.listItem}
              onClick={() => handleSingleJob(data._id)}
            >
              {data.jobTitle?.en ? data.jobTitle[languageToUse] : ''}
            </li>
          );
        })}
      </ul>
    );
  };

  const handleAddJob = (values, { resetForm }) => {
    const catArr = [];
    const skillsArr = [];
    console.log('selectedSkills :', selectedSkills);
    console.log('selectedCategories :', selectedCategories);
    selectedCategories.forEach(data => catArr.push(data._id));
    selectedSkills.forEach(data => skillsArr.push(data._id));
    console.log('selectedCategories :', selectedCategories);
    console.log('selectedSkills :', selectedSkills);

    if (renderSkill) {
      setRenderSkill(false);
    }

    if (selectedJobId) {
      dispatch(
        updateJobRequest({
          id: selectedJobId,
          jobTitle: values.jobTitle,
          skills: skillsArr,
          categories: catArr,
        })
      );
    } else {
      dispatch(
        addNewJobRequest({
          jobTitle: values.jobTitle,
          skills: skillsArr,
          categories: catArr,
        })
      );
    }
    resetForm();
    clearData();
  };

  const handleDeleteJob = () => {
    dispatch(deleteJobRequest({ id: selectedJobId }));
    clearData();
  };

  const initialValues = {
    jobTitle: jobTitle,
  };

  const handleAddCategory = (selectedList, selectedItem) => {
    console.log('selectedItem :', selectedItem);
    console.log('selectedList :', selectedList);
    setSelectedCategories(selectedList);
  };

  const handleRemoveCategory = (selectedList, removedItem) => {
    console.log('removedItem :', removedItem);
    console.log('selectedList :', selectedList);
    setSelectedCategories(selectedList);
  };

  const handleAddSkills = (selectedList, selectedItem) => {
    console.log('selectedItem :', selectedItem);
    console.log('selectedList :', selectedList);
    setSelectedSkills(selectedList);
  };

  const handleRemoveSkills = (selectedList, removedItem) => {
    console.log('removedItem :', removedItem);
    console.log('selectedList :', selectedList);
    setSelectedSkills(selectedList);
  };

  const [renderSkill, setRenderSkill] = useState(false);

  const renderJobForm = () => {
    return (
      <div
        className={`${classes.formContainer} ${
          showJobForm ? '' : classes.hidden
        }`}
      >
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleAddJob}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className={classes.formInnerContainer}>
                  <div>
                    <Typography id="non-linear-slider" gutterBottom>
                      <TranslateText id="job_title" /> en
                    </Typography>
                    <TextField
                      hiddenLabel
                      name="jobTitle.en"
                      id="demo-helper-text-misaligned"
                      variant="outlined"
                      autoComplete="off"
                      color="primary"
                      size="small"
                      fullWidth
                      value={values.jobTitle?.en}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => {
                        if (renderSkill) {
                          setRenderSkill(false);
                        }
                      }}
                      error={touched.jobTitle?.en && !!errors.jobTitle?.en}
                      helperText={
                        touched.jobTitle?.en ? errors.jobTitle?.en : false
                      }
                    />
                    <Typography id="non-linear-slider" gutterBottom>
                      <TranslateText id="job_title" /> de
                    </Typography>
                    <TextField
                      hiddenLabel
                      name="jobTitle.de"
                      id="demo-helper-text-misaligned"
                      variant="outlined"
                      autoComplete="off"
                      color="primary"
                      size="small"
                      fullWidth
                      value={values.jobTitle?.de}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => {
                        if (renderSkill) {
                          setRenderSkill(false);
                        }
                      }}
                      error={touched.jobTitle?.de && !!errors.jobTitle?.de}
                      helperText={
                        touched.jobTitle?.de ? errors.jobTitle?.de : false
                      }
                    />
                  </div>
                  <div>
                    <Typography id="non-linear-slider" gutterBottom>
                      <TranslateText id="select_category" />
                    </Typography>
                    <Multiselect
                      options={allCategories} // Options to display in the dropdown
                      selectedValues={selectedCategories} // Preselected value to persist in dropdown
                      onSelect={handleAddCategory} // Function will trigger on select event
                      onRemove={handleRemoveCategory} // Function will trigger on remove event
                      displayValue="categoryName" // Property name to display in the dropdown options
                    />
                  </div>
                  {selectedSkills && selectedSkills.length ? (
                    <>
                      Selected Skills:{' '}
                      {selectedSkills
                        .map(data =>
                          data.skillName.split(' / ').length === 2
                            ? data.skillName.split(' / ')[
                                languageToUse === 'en' ? 0 : 1
                              ]
                            : data.skillName
                        )
                        .join(', ')}
                    </>
                  ) : (
                    ''
                  )}
                  <Button
                    type="button"
                    onClick={() => setRenderSkill(!renderSkill)}
                  >
                    Add/Edit Skills
                  </Button>
                  {renderSkill ? (
                    <div>
                      <Typography id="non-linear-slider" gutterBottom>
                        <TranslateText id="select_skills" />
                      </Typography>
                      <Multiselect
                        options={allSkills} // Options to display in the dropdown
                        selectedValues={selectedSkills} // Preselected value to persist in dropdown
                        onSelect={handleAddSkills} // Function will trigger on select event
                        onRemove={handleRemoveSkills} // Function will trigger on remove event
                        displayValue="skillName" // Property name to display in the dropdown options
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={classes.buttonContainer}>
                  <Button type="submit">
                    <TranslateText id="save" />
                  </Button>
                  {selectedJobId && (
                    <Button
                      onClick={() => {
                        handleDeleteJob();
                        if (renderSkill) {
                          setRenderSkill(false);
                        }
                      }}
                    >
                      <TranslateText id="delete" />
                    </Button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  const renderJobsData = () => {
    return (
      <div className={classes.container}>
        <div className={classes.listWidth}>
          <div className={classes.searchContainer}>
            <div className={classes.fullWidth}>
              <InputBase
                onChange={e => {
                  setTyping(e.target.value);
                  if (renderSkill) {
                    setRenderSkill(false);
                  }
                }}
                fullWidth
                value={typing}
                placeholder={intl.formatMessage({ id: 'search' })}
              />
            </div>
            <div className={classes.cursor} onClick={() => showForm()}>
              <BsPlusLg />
            </div>
          </div>
          <div>{allJobsList && renderJobsList()}</div>
        </div>
        {renderJobForm()}
      </div>
    );
  };

  return <Skeleton>{renderJobsData()}</Skeleton>;
};

export default AllJobs;
