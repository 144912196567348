import axios from "axios";

let BaseURLValue = "";
if (process.env.REACT_APP_ENV === 'development') BaseURLValue = process.env.REACT_APP_BASE_URL_DEVELOPMENT
else if (process.env.REACT_APP_ENV === 'staging') BaseURLValue = process.env.REACT_APP_BASE_URL_STAGING
else if (process.env.REACT_APP_ENV === 'production') BaseURLValue = process.env.REACT_APP_BASE_URL_PRODUCTION

let FileBaseURL = "";
if (process.env.REACT_APP_ENV === 'development') FileBaseURL = process.env.REACT_APP_FILES_DEVELOPMENT_BASE_URL
else if (process.env.REACT_APP_ENV === 'staging') FileBaseURL = process.env.REACT_APP_FILES_STAGING_BASE_URL
else if (process.env.REACT_APP_ENV === 'production') FileBaseURL = process.env.REACT_APP_FILES_PRODUCTION_BASE_URL

let APIKeyValue = "";
if (process.env.REACT_APP_ENV === 'development') APIKeyValue = process.env.REACT_APP_DEVELOPMENT_X_API_KEY
else if (process.env.REACT_APP_ENV === 'staging') APIKeyValue = process.env.REACT_APP_STAGING_X_API_KEY
else if (process.env.REACT_APP_ENV === 'production') APIKeyValue = process.env.REACT_APP_PRODUCTION_X_API_KEY

const skillookAdmin = axios.create({
  baseURL: BaseURLValue,
  headers: {
    "x-api-key": APIKeyValue,
    "Content-Type": "application/json",
  },
});

skillookAdmin.interceptors.request.use(
  function (config) {
    const token = "Bearer " + localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    config.headers['Accept-language'] = localStorage.getItem("language") || 'en';
    config.headers.language = localStorage.getItem("language") || 'en';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { FileBaseURL };
export default skillookAdmin;
