import {  allJobsListRequest,  searchJobsListRequest,} from 'features/allJobs/jobsListSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useAllJobsList = (keyword, start, limit) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (keyword.trim()) {
      dispatch(searchJobsListRequest([keyword, start, limit]));
    } else {
      dispatch(allJobsListRequest([start, limit]));
    }
  }, [dispatch, keyword, limit, start]);

  return useSelector(state => {
    if (state.jobs.list) return Object.values(state.jobs.list);
    return [];
  });
};

export default useAllJobsList;
