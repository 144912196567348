import React, { useState, useEffect } from 'react';
import Skeleton from 'components/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  addNewCategoryRequest,
  deleteCategoryRequest,
  emptyAllCategoryList,
  getSingleCategoryRequest,
  updateCategoryRequest,
} from './categoriesListSlice';
import TranslateText from 'translation/TranslateText';
import useAllCategoriesList from 'customHooks/useAllCategoriesList';
import { BsPlusLg } from 'react-icons/bs';
import { Button, InputBase, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import usePagination from 'customHooks/usePagination';
import { Formik } from 'formik';
import { string, object } from 'yup';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    gap: '40px',
  },
  listWidth: {
    width: '300px',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    justifyContent: 'space-between',
    padding: '4px',
    border: '2px solid #4B545C',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
  listContainer: {
    height: '500px',
    overflow: 'hidden auto',
    margin: 0,
  },
  listItem: {
    padding: '10px',
    color: 'white',
    background: '#34495e',
    marginBottom: '1px',
    cursor: 'pointer',
    transition: '0.75s all',
  },
  formContainer: {
    width: '50%',
  },
  formButtonContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const Categories = () => {
  const limit = 20;
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const [callStart, setCallStart] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [categoryName, setCategoryName] = useState({
    en: '',
    de: '',
  });
  const [selectedCategoryId, setSelectedCateoryId] = useState('');
  const [showCategoryForm, setShowCategoryForm] = useState(false);

  const allCategoriesList = useAllCategoriesList(keyword, callStart, limit);
  const totalCategoriesCount = useSelector(
    state => state.allCategories.totalCount
  );
  const singleCategory = useSelector(
    state => state.allCategories.singleCategory
  );

  const scrollElementID = 'scrollElement';

  usePagination(totalCategoriesCount, callStart, setCallStart, scrollElementID);

  useEffect(() => {
    if (singleCategory?.categoryName) {
      setCategoryName(singleCategory?.categoryName);
    }
  }, [singleCategory]);

  useEffect(() => {
    setCallStart(0);
    dispatch(emptyAllCategoryList());
  }, [dispatch]);

  const clearData = () => {
    setKeyword('');
    setCategoryName({
      en: '',
      de: '',
    });
    setSelectedCateoryId('');
    setShowCategoryForm(false);
  };

  const handleSingleCategory = id => {
    setSelectedCateoryId(id);
    setShowCategoryForm(true);
    dispatch(getSingleCategoryRequest({ id: id }));
  };

  const handleAddCategory = (values, { resetForm }) => {
    if (selectedCategoryId) {
      dispatch(
        updateCategoryRequest({
          id: selectedCategoryId,
          category: values.categoryName,
        })
      );
    } else {
      dispatch(addNewCategoryRequest({ category: values.categoryName }));
    }
    resetForm();
    clearData();
  };

  const languageToUse = localStorage.getItem('language') || 'en';

  const renderCategoriesList = () => {
    return (
      <ul
        id={scrollElementID}
        className={`${classes.listContainer} scrollElement`}
      >
        {allCategoriesList.map(data => {
          return (
            <li
              key={data._id}
              className={classes.listItem}
              onClick={() => handleSingleCategory(data._id)}
            >
              {data.categoryName[languageToUse]}
            </li>
          );
        })}
      </ul>
    );
  };

  const showForm = () => {
    setSelectedCateoryId('');
    setCategoryName({
      en: '',
      de: '',
    });
    setShowCategoryForm(true);
  };

  const handleDeleteCategory = () => {
    dispatch(deleteCategoryRequest({ id: selectedCategoryId }));
    clearData();
  };

  const validationSchema = object().shape({
    categoryName: string()
      .matches(
        /^((?=.*[a-z]).+)$/,
        intl.formatMessage({ id: 'enter_category_name' })
      )
      .required(intl.formatMessage({ id: 'required' })),
  });

  const initialValues = {
    categoryName: categoryName,
  };

  return (
    <Skeleton>
      <div className={classes.container}>
        <div className={classes.listWidth}>
          <div className={classes.searchContainer}>
            <div style={{ width: '100%' }}>
              <InputBase
                fullWidth
                placeholder={intl.formatMessage({ id: 'search' })}
                onChange={e => {
                  setCallStart(0);
                  setKeyword(e.target.value);
                }}
                value={keyword}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => showForm()}>
              <BsPlusLg />
            </div>
          </div>
          <div>{allCategoriesList && renderCategoriesList()}</div>
        </div>
        {showCategoryForm && (
          <div className={classes.formContainer}>
            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleAddCategory}
                enableReinitialize={true}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div>
                      <Typography id="non-linear-slider" gutterBottom>
                        <TranslateText id="category_name" /> en
                      </Typography>
                      <TextField
                        hiddenLabel
                        name="categoryName.en"
                        color="primary"
                        autoComplete="off"
                        variant="outlined"
                        id="demo-helper-text-misaligned"
                        size="small"
                        fullWidth
                        value={values.categoryName.en}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.categoryName && !!errors.categoryName}
                        helperText={
                          touched.categoryName ? errors.categoryName : false
                        }
                        style={{
                          marginBottom: '10px',
                        }}
                      />
                      <Typography id="non-linear-slider" gutterBottom>
                        <TranslateText id="category_name" /> de
                      </Typography>
                      <TextField
                        hiddenLabel
                        name="categoryName.de"
                        color="primary"
                        autoComplete="off"
                        variant="outlined"
                        id="demo-helper-text-misaligned"
                        size="small"
                        fullWidth
                        value={values.categoryName.de}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.categoryName && !!errors.categoryName}
                        helperText={
                          touched.categoryName ? errors.categoryName : false
                        }
                      />
                    </div>
                    <div className={classes.formButtonContainer}>
                      <Button type="submit">
                        <TranslateText id="save" />
                      </Button>
                      {selectedCategoryId && (
                        <Button onClick={() => handleDeleteCategory()}>
                          <TranslateText id="delete" />
                        </Button>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </Skeleton>
  );
};

export default Categories;
