import { useEffect } from 'react';

const usePagination = (list, callStart, handleCallStart, scrollElementID) => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight =
        document.getElementById(scrollElementID).scrollHeight;
      const offsetHeight =
        document.getElementById(scrollElementID).offsetHeight;
      const scrollTop = document.getElementById(scrollElementID).scrollTop;

      // Various condition to pagination 3 one is stoping
      // it for further api call if data returned is empty
      console.log('scroll:', scrollHeight - offsetHeight, scrollTop + 10, scrollHeight - offsetHeight <= scrollTop + 10);
      if (
        scrollHeight - offsetHeight <= scrollTop + 10 &&
        list >= callStart + 10
      ) {
        console.log('callStart :', callStart);
        handleCallStart(callStart + 10);
      }
    };

    document
      .getElementById(scrollElementID)
      .addEventListener('scroll', handleScroll);

    return function cleanup() {
      document
        .getElementById(scrollElementID)
        .removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, [callStart, list]);

  return callStart;
};

export default usePagination;
