import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import { Nav } from 'react-bootstrap';
import * as routes from 'routes';
import { ImUserMinus, ImProfile } from 'react-icons/im';
import { FaUserAlt } from 'react-icons/fa';
import { MdLanguage } from 'react-icons/md';
import { SiSkillshare } from 'react-icons/si';
import { GiSkills } from 'react-icons/gi';
import { BiCategoryAlt } from 'react-icons/bi';

import SkillookLogo from 'images/Skillook.png';
import { showLanguagePopup } from 'components/ConfirmPopup/popupSlice';
import TranslateText from 'translation/TranslateText';
import Popup from 'components/ConfirmPopup';

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1rem !important',
  color: 'rgba(255, 255, 255, .6)',
  fontWeight: '400 !important',
  cursor: 'pointer',
  transition: 'color .2s ease-in-out',
  borderBottom: '1px solid #4b545c',
  textDecoration: 'none',
  padding: '.4rem 1.4rem 1.4rem 1.4rem',

  '&:hover': {
    color: 'rgba(255, 255, 255, .95)',
  },
};

const useStyles = makeStyles({
  root: {
    background: '#1F1A3C',
    boxShadow: '0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22)',
    overflowY: 'hidden',
    zIndex: 100,
    height: 'inherit',
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    width: 'var(--width-sideBar)',
    transition: 'transform .35s ease-in',

    '@media only screen and (max-width: 56.25em)': {
      transform: 'translateX(-25rem)',
    },
  },
  link: {
    textDecoration: 'none',
  },

  sidebar: {
    height: 'calc(100% - (3.5rem + 1px))',
    overflowY: 'auto',
    padding: '0 .5rem',
    marginTop: '30px',
    color: 'white',
  },

  icon: {
    fontSize: '.8rem',
    marginLeft: '.6rem',
  },

  logo: {
    margin: '16px',
    height: '60px',
  },

  active: {
    transform: 'translateX(0)',
  },
  sidebarHeading: {
    fontSize: '27px !important',
    textAlign: 'right',
    fontWeight: 'bold !important',
    color: 'white',
    marginTop: '-15px',
  },
  navbarIcon: {
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
  },
  navItem: {
    padding: '1px 0',
  },
});

const SideBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [activePath] = useState(window.location.pathname);
  const sideBarStatus = useSelector(state => state.sideBar);
  const popupDisplay = useSelector(state => state.popup.changeLanguage);

  const handleClick = (e, path) => {
    history.push(path);
    e.stopPropagation();
  };

  const handleChangeLanguage = () => {
    dispatch(showLanguagePopup({ changeLanguage: true }));
  };

  return (
    <>
      <aside
        className={`${classes.root} ${sideBarStatus ? classes.active : ''}`}
      >
        <Link className={classes.link} to="/">
          <img src={SkillookLogo} className={classes.logo} alt="SkillookLogo" />
          <ThemeProvider theme={theme}>
            <Typography variant="h3" className={classes.sidebarHeading}>
              <TranslateText id="admin_panel" />
            </Typography>
          </ThemeProvider>
        </Link>

        <div className={classes.sidebar}>
          <Nav.Item className={classes.navItem}>
            <Nav.Link
              onClick={e => handleClick(e, routes.ALL_USERS)}
              style={{
                color: activePath === routes.ALL_USERS ? '#4eafa4' : 'white',
              }}
              active={activePath === routes.ALL_USERS ? true : false}
            >
              <div className={classes.navbarIcon}>
                <FaUserAlt
                  fill={activePath === routes.ALL_USERS ? '#4eafa4' : 'white'}
                />
              </div>
              <TranslateText id="all_users" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className={classes.navItem}>
            <Nav.Link
              onClick={e => handleClick(e, routes.ALL_REPORTED_USERS)}
              style={{
                color:
                  activePath === routes.ALL_REPORTED_USERS
                    ? '#4eafa4'
                    : 'white',
              }}
              active={activePath === routes.ALL_REPORTED_USERS ? true : false}
            >
              <div className={classes.navbarIcon}>
                <ImUserMinus
                  fill={
                    activePath === routes.ALL_REPORTED_USERS
                      ? '#4eafa4'
                      : 'white'
                  }
                />
              </div>
              <TranslateText id="all_reported_users" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className={classes.navItem}>
            <Nav.Link
              onClick={e => handleClick(e, routes.CATEGORY)}
              style={{
                color: activePath === routes.CATEGORY ? '#4eafa4' : 'white',
              }}
              active={activePath === routes.CATEGORY ? true : false}
            >
              <div className={classes.navbarIcon}>
                <BiCategoryAlt
                  fill={activePath === routes.CATEGORY ? '#4eafa4' : 'white'}
                />
              </div>
              <TranslateText id="categories" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className={classes.navItem}>
            <Nav.Link
              onClick={e => handleClick(e, routes.SKILLS)}
              style={{
                color: activePath === routes.SKILLS ? '#4eafa4' : 'white',
              }}
              active={activePath === routes.SKILLS ? true : false}
            >
              <div className={classes.navbarIcon}>
                <GiSkills
                  fill={activePath === routes.SKILLS ? '#4eafa4' : 'white'}
                />
              </div>
              <TranslateText id="skills" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className={classes.navItem}>
            <Nav.Link
              onClick={e => handleClick(e, routes.JOBS)}
              style={{
                color: activePath === routes.JOBS ? '#4eafa4' : 'white',
              }}
              active={activePath === routes.JOBS ? true : false}
            >
              <div className={classes.navbarIcon}>
                <ImProfile
                  fill={activePath === routes.JOBS ? '#4eafa4' : 'white'}
                />
              </div>
              <TranslateText id="jobs" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className={classes.navItem}>
            <Nav.Link
              onClick={e => handleClick(e, routes.MATCHING_PERCENTAGE)}
              style={{
                color:
                  activePath === routes.MATCHING_PERCENTAGE
                    ? '#4eafa4'
                    : 'white',
              }}
              active={activePath === routes.MATCHING_PERCENTAGE ? true : false}
            >
              <div className={classes.navbarIcon}>
                <SiSkillshare
                  fill={
                    activePath === routes.MATCHING_PERCENTAGE
                      ? '#4eafa4'
                      : 'white'
                  }
                />
              </div>
              <TranslateText id="matching_percentage" />
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className={classes.navItem}>
            <Nav.Link onClick={handleChangeLanguage}>
              <div className={classes.navbarIcon}>
                <MdLanguage />
              </div>
              <TranslateText id="change_language" />
            </Nav.Link>
          </Nav.Item>
        </div>
      </aside>
      {popupDisplay ? <Popup parent="sidebar" /> : false}
    </>
  );
};

export default SideBar;
