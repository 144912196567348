import React, { useState, useEffect } from 'react';
import Skeleton from 'components/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputBase, Typography } from '@material-ui/core';
import { BsPlusLg } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import useAllSkillsList from 'customHooks/useAllSkillsList';
import TextField from '@material-ui/core/TextField';
import TranslateText from 'translation/TranslateText';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewSkillRequest,
  deleteSkillRequest,
  emptyAllSkillList,
  getSingleSkillRequest,
  updateSkillRequest,
} from './skillListSlice';
import usePagination from 'customHooks/usePagination';
import { Formik } from 'formik';
import { string, object } from 'yup';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '40px',
    width: '100%',
  },
  listWidth: {
    width: '300px',
  },
  searchContainer: {
    padding: '4px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #4B545C',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
  },
  listContainer: {
    margin: 0,
    overflow: 'hidden auto',
    height: '500px',
  },
  listItem: {
    color: 'white',
    padding: '10px',
    cursor: 'pointer',
    marginBottom: '1px',
    background: '#34495e',
    transition: '0.75s all',
  },
  formContainer: {
    width: '50%',
  },
  formButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
  },
});

const Skills = () => {
  const classes = useStyles();
  const scrollElementID = 'scrollElement';

  const dispatch = useDispatch();
  const intl = useIntl();
  const [keyword, setKeyword] = useState('');
  const [callStart, setCallStart] = useState(0);
  const [showSkillForm, setShowSkillForm] = useState(false);
  const limit = 20;
  const [skillName, setSkillName] = useState({
    en: '',
    de: '',
  });
  const [selectedSkillId, setSelectedSkillId] = useState('');

  const allSkillsList = useAllSkillsList(keyword, callStart, limit);
  const totalSkillsCount = useSelector(state => state.skills.totalCount);
  const singleSkill = useSelector(state => state.skills.singleSkillData);
  usePagination(totalSkillsCount, callStart, setCallStart, scrollElementID);

  useEffect(() => {
    setCallStart(0);
    dispatch(emptyAllSkillList());
  }, [dispatch]);

  useEffect(() => {
    if (singleSkill?.skillName) {
      setSkillName(singleSkill?.skillName);
    }
  }, [singleSkill]);

  const clearData = () => {
    setKeyword('');
    setSkillName({
      en: '',
      de: '',
    });
    setSelectedSkillId('');
    setShowSkillForm(false);
  };

  const showForm = () => {
    setSelectedSkillId('');
    setSkillName({
      en: '',
      de: '',
    });
    setShowSkillForm(true);
  };

  const handleSingleSkill = id => {
    setSelectedSkillId(id);
    setShowSkillForm(true);
    dispatch(getSingleSkillRequest({ id: id }));
  };

  const handleAddSkill = (values, { resetForm }) => {
    if (selectedSkillId) {
      dispatch(
        updateSkillRequest({ id: selectedSkillId, skillName: values.skillName })
      );
    } else {
      dispatch(addNewSkillRequest({ skillName: values.skillName }));
    }
    resetForm();
    clearData();
  };

  const handleDeleteSkill = () => {
    dispatch(deleteSkillRequest({ id: selectedSkillId }));
    clearData();
  };

  const languageToUse = localStorage.getItem('language') || 'en';

  const renderSkillsList = () => {
    return (
      <ul
        id={scrollElementID}
        className={`${classes.listContainer} scrollElement`}
      >
        {allSkillsList.map(data => {
          return (
            <li
              key={data._id}
              className={classes.listItem}
              onClick={() => handleSingleSkill(data._id)}
            >
              {data.skillName[languageToUse]}
            </li>
          );
        })}
      </ul>
    );
  };

  const validationSchema = object().shape({
    skillName: object().shape({
      en: string().matches(
        /^((?=.*[a-z]).+)$/,
        intl.formatMessage({ id: 'enter_skill_name' })
      ),
      de: string().matches(
        /^((?=.*[a-z]).+)$/,
        intl.formatMessage({ id: 'enter_skill_name' })
      ),
    }),
  });

  const initialValues = {
    skillName: skillName,
  };

  const renderSkillForm = () => {
    return (
      <div className={classes.formContainer}>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleAddSkill}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div>
                  <Typography id="non-linear-slider" gutterBottom>
                    <TranslateText id="skill_name" /> en
                  </Typography>
                  <TextField
                    hiddenLabel
                    name="skillName.en"
                    id="demo-helper-text-misaligned"
                    variant="outlined"
                    autoComplete="off"
                    color="primary"
                    fullWidth
                    size="small"
                    value={values.skillName.en}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.skillName?.en && !!errors.skillName?.en}
                    helperText={
                      touched.skillName?.en ? errors.skillName?.en : false
                    }
                    style={{
                      marginBottom: '10px',
                    }}
                  />

                  <Typography id="non-linear-slider" gutterBottom>
                    <TranslateText id="skill_name" /> de
                  </Typography>
                  <TextField
                    hiddenLabel
                    name="skillName.de"
                    id="demo-helper-text-misaligned"
                    variant="outlined"
                    autoComplete="off"
                    color="primary"
                    fullWidth
                    size="small"
                    value={values.skillName.de}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.skillName?.de && !!errors.skillName?.de}
                    helperText={
                      touched.skillName?.de ? errors.skillName?.de : false
                    }
                  />
                </div>
                <div className={classes.formButtonContainer}>
                  <Button type="submit">
                    <TranslateText id="save" />
                  </Button>
                  {selectedSkillId && (
                    <Button onClick={() => handleDeleteSkill()}>
                      <TranslateText id="delete" />
                    </Button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  return (
    <Skeleton>
      <div className={classes.container}>
        <div className={classes.listWidth}>
          <div className={classes.searchContainer}>
            <div style={{ width: '100%' }}>
              <InputBase
                fullWidth
                value={keyword}
                placeholder={intl.formatMessage({ id: 'search' })}
                onChange={e => {
                  setCallStart(0);
                  setKeyword(e.target.value);
                }}
              />
            </div>
            <div onClick={() => showForm()} style={{ cursor: 'pointer' }}>
              <BsPlusLg />
            </div>
          </div>
          <div>{allSkillsList && renderSkillsList()}</div>
        </div>
        {showSkillForm && renderSkillForm()}
      </div>
    </Skeleton>
  );
};

export default Skills;
