import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  allCategoriesListRequest,
  searchCategoriesListRequest,
} from 'features/allCategories/categoriesListSlice';

const useAllCategoriesList = (keyword, start, limit, modify = false) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (keyword.trim()) {
      dispatch(searchCategoriesListRequest([keyword, start, limit]));
    } else {
      dispatch(allCategoriesListRequest([start, limit]));
    }
  }, [keyword, start, limit, dispatch]);

  return useSelector(state => {
    if (state.allCategories.list) {
      let list = Object.values(state.allCategories.list);
      if (modify && Object.keys(state.allCategories.list).length) {
        list = list.map(data => {
          return {
            _id: data._id,
            categoryName: data.categoryName.en + ' / ' + data.categoryName.de
          }
        });
      }
      return list;
    }
    return [];
  });
};

export default useAllCategoriesList;
