import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  allSkillsListRequest,
  searchSkillsListRequest,
} from 'features/allSkills/skillListSlice';

const useAllSkillsList = (keyword, start, limit, modify = false) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (keyword.trim()) {
      dispatch(searchSkillsListRequest([keyword, start, limit]));
    } else {
      dispatch(allSkillsListRequest([start, limit]));
    }
  }, [dispatch, keyword, limit, start]);

  return useSelector(state => {
    if (state.skills.list) {
      let list = Object.values(state.skills.list);
      if (modify && Object.keys(state.skills.list).length) {
        list = list.map(data => {
          return {
            _id: data._id,
            skillName: data.skillName.en + ' / ' + data.skillName.de
          }
        });
      }
      return list;
    }
    return [];
  });
};

export default useAllSkillsList;
