import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from 'components/Skeleton';
import {
  blockUnblockUserRequest,
  emptyAllUserList,
} from 'features/allUsers/allUserListSlice';
import Pagination from '@material-ui/lab/Pagination';
import useAllUserList from 'customHooks/useAllUserList';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TranslateText from 'translation/TranslateText';
import { AiFillDelete } from 'react-icons/ai';
import { showPopup } from 'components/ConfirmPopup/popupSlice';
import Popup from 'components/ConfirmPopup';

const button = {
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  fontSize: '14px',
  paddingLeft: '10px',
};

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
  },
  container: {
    margin: '1rem',
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '1rem',
  },
  unBlockButton: {
    ...button,
    color: 'green',
  },
  blockButton: {
    ...button,
    color: 'red',
  },
  deleteButton: {
    ...button,
    fontSize: '24px',
    color: 'red',
  },
  status: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    margin: '0 auto',
  },
  activate: {
    background: 'green',
  },
  deactivate: {
    background: 'red',
  },
  pagination: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '60px',
  },
});

const AllUsers = () => {
  const limit = 10;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [filter, setFilter] = useState(3);
  const [callStart, setCallStart] = useState(0);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [refresh, setRefresh] = useState(false);

  const allUsersList = useAllUserList(
    keyword,
    filter,
    callStart,
    limit,
    refresh
  );

  const totalUsersCount = useSelector(state => state.allUserList.totalUsers);
  const popupDisplay = useSelector(state => state.popup.visibility);
  const refreshPage = useSelector(state => state.allUserList.refreshPage);

  useEffect(() => {
    setCallStart((page - 1) * limit);
  }, [page]);

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setCallStart(0);
    dispatch(emptyAllUserList());
  }, [dispatch]);

  useEffect(() => {
    setRefresh(!refresh);
  }, [refreshPage]);

  const totalPages_pre = totalUsersCount / limit;
  const totalPages =
    totalUsersCount % limit === 0
      ? totalPages_pre
      : Math.trunc(totalPages_pre) + 1;

  const handleBlockUnblock = (userID, status) => {
    dispatch(blockUnblockUserRequest({ userId: userID, status: status }));
  };

  const handleChange = e => {
    setFilter(e.target.value);
    handlePageChange(e, 1);
    setKeyword('');
  };

  const handleDelete = id => {
    console.log('delete id', id);
    dispatch(showPopup({ visibility: true, id }));
  };

  const renderRow = () => {
    return allUsersList.map((item, index) => {
      return (
        <TableRow key={item._id}>
          <TableCell align="center">{index + 1}</TableCell>
          <TableCell align="center">
            {item.fullName} {item?.companyDetails?.companyName}
          </TableCell>
          <TableCell align="center">{item.email}</TableCell>
          <TableCell align="center">
            {item?.companyDetails?.country} {item?.contact?.country}
          </TableCell>
          <TableCell align="center">
            <div
              className={`${classes.status} ${
                item.status ? classes.activate : classes.deactivate
              }`}
            ></div>
          </TableCell>
          <TableCell align="center" scope="row">
            <button
              onClick={() => handleDelete(item._id)}
              className={classes.deleteButton}
            >
              <AiFillDelete />
            </button>
          </TableCell>
          <TableCell align="center">
            {item.status ? (
              <button
                onClick={() => handleBlockUnblock(item._id, false)}
                className={classes.blockButton}
              >
                <TranslateText id="block" />
              </button>
            ) : (
              <button
                onClick={() => handleBlockUnblock(item._id, true)}
                className={classes.unBlockButton}
              >
                <TranslateText id="unblock" />
              </button>
            )}
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    return (
      <>
        <div className={classes.filter}>
          <TextField
            label={<TranslateText id="search" />}
            color="primary"
            value={keyword}
            helperText={<TranslateText id="search_helperText" />}
            id="demo-helper-text-misaligned"
            onChange={e => {
              setKeyword(e.target.value);
              handlePageChange(e, 1);
            }}
          />
          <Select
            name="subType"
            labelId="formSelectLabel"
            id="formSelect"
            label="Type"
            value={filter}
            onChange={handleChange}
          >
            <MenuItem value={3}>
              <TranslateText id="all" />
            </MenuItem>
            <MenuItem value={1}>
              <TranslateText id="candidate" />
            </MenuItem>
            <MenuItem value={2}>
              <TranslateText id="company" />
            </MenuItem>
          </Select>
        </div>
        <TableContainer className={classes.container} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <TranslateText id="s_no" />
                </TableCell>
                <TableCell align="center">
                  <TranslateText id="user_name" /> /{' '}
                  <TranslateText id="company_name" />
                </TableCell>
                <TableCell align="center">
                  <TranslateText id="email" />
                </TableCell>
                <TableCell align="center">
                  <TranslateText id="country" />
                </TableCell>
                <TableCell align="center">
                  <TranslateText id="status" />
                </TableCell>
                <TableCell align="center">
                  <TranslateText id="action" />
                </TableCell>
                <TableCell align="center">
                  <TranslateText id="block" /> / <TranslateText id="unblock" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{allUsersList && renderRow()}</TableBody>
          </Table>
        </TableContainer>
        <div className={classes.pagination}>
          <Pagination
            color="primary"
            count={totalPages ? totalPages : 0}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      </>
    );
  };

  return (
    <Skeleton>
      {renderTable()}
      {popupDisplay ? <Popup parent="user" /> : false}
    </Skeleton>
  );
};

export default AllUsers;
